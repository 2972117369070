import { useCallback, useEffect, useRef } from 'react';

interface LongPollingOptions {
  shouldCallImmediately?: boolean;
  deps?: any[];
  shouldWatchCallback?: boolean;
  disabled?: boolean;
}

export const useLongPolling = (callback: () => Promise<any>, delay: number, options?: LongPollingOptions) => {
  const { deps = [], shouldCallImmediately = true, shouldWatchCallback = true, disabled = false } = options || {};

  const dependencies = shouldWatchCallback ? [...deps, callback] : deps;

  const requestRef = useRef<Promise<any> | undefined>();
  const timeoutIdRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const clearTimeoutRef = useCallback(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = undefined;
    }
  }, []);

  const debouncedCallback = useCallback(async () => {
    timeoutIdRef.current = undefined;
    if (requestRef.current) {
      return;
    }
    requestRef.current = callback().finally(() => {
      requestRef.current = undefined;
      timeoutIdRef.current = setTimeout(() => {
        debouncedCallback();
      }, delay);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, delay]);

  useEffect(() => {
    if (!disabled) {
      if (shouldCallImmediately) {
        debouncedCallback();
      } else {
        timeoutIdRef.current = setTimeout(() => {
          debouncedCallback();
        }, delay);
      }

      return () => {
        clearTimeoutRef();
        requestRef.current = undefined;
      };
    }
    return () => {
      clearTimeoutRef();
    };
  }, [delay, debouncedCallback, shouldCallImmediately, disabled, clearTimeoutRef]);

  return requestRef.current;
};

export default useLongPolling;
