import { useCallback } from 'react';
import { Helmet } from 'react-helmet';

import Iframe from 'components/Iframe';

import { useScrollIframeToAnchor } from './utils';
import { useAppContext } from 'contexts/AppContext';
import { useModalsContext } from 'contexts/ModalsContext';

const LandingPage = function () {
  const [setIframeOnLoad] = useScrollIframeToAnchor();
  const { canonicalHref, isImmers } = useAppContext();
  const landingToShow = !isImmers ? '/staticPages/LandingPage.html' : '/staticPages/ImmersLandingPage.html';

  const { openServiceDevelopmentRequestModal, openDemoRequestModal, openContactRequestModal } = useModalsContext();

  const messageEventHandler = useCallback(
    (event: MessageEvent<any>) => {
      if (event?.data === 'openServiceDevelopmentRequestModal') {
        openServiceDevelopmentRequestModal();
      } else if (event?.data === 'openDemoRequestModal') {
        openDemoRequestModal();
      } else if (event?.data === 'openContactRequestModal') {
        openContactRequestModal();
      }
    },
    [openContactRequestModal, openDemoRequestModal, openServiceDevelopmentRequestModal]
  );

  return (
    <div className='content-page' style={{ padding: '0 0', width: '100%' }}>
      <Helmet>{canonicalHref && <link rel='canonical' href={canonicalHref} />}</Helmet>
      <Iframe
        src={landingToShow}
        title='landing iframe'
        fullHeight
        onLoadControlled={setIframeOnLoad}
        messageEventHandler={messageEventHandler}
      />
    </div>
  );
};

export default LandingPage;
