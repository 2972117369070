import { createContext, FunctionComponent, useContext } from 'react';

import { useAccessRequestModal } from 'components/Modals/AccessRequestModal';
import { RequestType } from 'components/Modals/AccessRequestModal/types';
import { useServiceDevelopmentRequestModal } from 'components/Modals/ServiceDevelopmentRequestModal';
import { useDemoRequestModal } from 'components/Modals/DemoRequestModal';
import { useContactRequestModal } from 'components/Modals/ContactRequestModal';
import { useMessageSentNotificationModal } from 'components/Modals/MessageSentNotificationModal';

export type ModalsContextType = {
  openAccessRequestModal: (
    data?:
      | {
          requestType?: RequestType | undefined;
        }
      | undefined
  ) => void;
  openServiceDevelopmentRequestModal: () => void;
  openDemoRequestModal: () => void;
  openContactRequestModal: () => void;
};

export const ModalsContext = createContext({} as ModalsContextType);

interface ModalsContextProviderProps {}

export const ModalsContextProviderComponent: FunctionComponent<ModalsContextProviderProps> = ({ children }) => {
  const [MessageSentNotificationModalNode, openMessageSentNotificationModal] = useMessageSentNotificationModal();

  const [AccessRequestModalNode, openAccessRequestModal] = useAccessRequestModal();

  const [ServiceDevelopmentRequestModalNode, openServiceDevelopmentRequestModal] = useServiceDevelopmentRequestModal({
    onResolve: openMessageSentNotificationModal,
  });

  const [DemoRequestModalNode, openDemoRequestModal] = useDemoRequestModal({
    onResolve: openMessageSentNotificationModal,
  });

  const [ContactRequestModalNode, openContactRequestModal] = useContactRequestModal({
    onResolve: openMessageSentNotificationModal,
  });

  return (
    <ModalsContext.Provider
      value={{
        openAccessRequestModal,
        openServiceDevelopmentRequestModal,
        openDemoRequestModal,
        openContactRequestModal,
      }}
    >
      {children}
      {AccessRequestModalNode}
      {ServiceDevelopmentRequestModalNode}
      {DemoRequestModalNode}
      {ContactRequestModalNode}
      {MessageSentNotificationModalNode}
    </ModalsContext.Provider>
  );
};

export const useModalsContext = () => useContext(ModalsContext);
