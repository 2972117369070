import { useForm, UseFormProps, UseFormReturn } from 'react-hook-form';

import ControlledInput from 'components/ControlledInput';
import FormContainer from 'modules/Account/components/FormLayout/FormContainer';

import { CloneFormFieldsType, CloneFormMethodsType, defaultFormValues } from './types';

import { t } from 'localization';
import styles from './styles.module.scss';

interface CloneFormInterface {
  formMethods: CloneFormMethodsType;
}

export const useCloneForm = (props?: UseFormProps<CloneFormFieldsType>): UseFormReturn<CloneFormFieldsType, any> => {
  return useForm<CloneFormFieldsType>({
    criteriaMode: 'all',
    shouldUnregister: true,
    defaultValues: { ...defaultFormValues },
    mode: 'all',
    reValidateMode: 'onChange',
    ...props,
  });
};

export default function CloneForm({ formMethods }: CloneFormInterface) {
  const {
    control,
    formState: { errors },
  } = formMethods;
  return (
    <FormContainer
      className={styles.cloneForm__container}
      showError={errors?.commonError}
      errorMessage={errors.commonError?.message}
    >
      <ControlledInput
        title={t('CloneModal:Form:Name')}
        data-test-id='CloneModal:Form:CloneNameField'
        name='name'
        placeholder={t('CloneModal:Form:NamePlaceholder')}
        control={control}
      />
    </FormContainer>
  );
}
