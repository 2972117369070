import { useCallback, useState } from 'react';

import { useAppContext, useDI } from 'contexts/AppContext';
import { useAuthContext } from 'contexts/AuthContext';
import { useLoading } from 'utils/hooks';

import { RefillFromCardResponse, ShortBalance } from 'api/CailaBillingApi/api/client';
import { BalanceInfoApiService } from 'services/ApiServices/BalanceInfoApiService';
import { RefillApiService } from 'services/ApiServices/RefillApiService';
import { AppLogger } from 'services/AppLogger';

export const useUserInfoBalance = (): {
  getBalance: () => Promise<void>;
  refillFromCard: (amountRub: number, redirectUrl: string) => Promise<RefillFromCardResponse | undefined>;
  refillFromIncomeBalance: (amountRub: number) => Promise<void>;
  checkIfUserIsBlocked: () => Promise<void>;
  state: {
    balanceInfo?: ShortBalance;
    isUserBlocked: boolean;
    isBillingEnabled: boolean;
  };
  isLoading: boolean;
} => {
  const { user, userId } = useAuthContext();
  const balanceInfoApi = useDI(BalanceInfoApiService);
  const refillApi = useDI(RefillApiService);

  const [isLoading, , startLoading, endLoading] = useLoading();
  const { isBillingEnabled } = useAppContext();
  const [balanceInfo, setBalanceInfo] = useState<ShortBalance | undefined>();
  const [isUserBlocked, setIsUserBlocked] = useState<boolean>(false);

  const getBalance = useCallback(async () => {
    if (!user || !isBillingEnabled) return;
    try {
      const { data: balanceData } = await balanceInfoApi.getShortBalance(user.accountId);
      setBalanceInfo(balanceData);
    } catch (error) {
      AppLogger.error({ exception: error }, { useThrottle: true });
    }
  }, [balanceInfoApi, isBillingEnabled, user]);

  const checkIfUserIsBlocked = useCallback(async () => {
    if (!user || !isBillingEnabled) return;
    startLoading();
    try {
      const { data: isUserBlocked } = await balanceInfoApi.checkBlocked(user.accountId);
      setIsUserBlocked(isUserBlocked);
    } catch (error) {
      AppLogger.error({ exception: error });
    }
    endLoading();
  }, [balanceInfoApi, endLoading, isBillingEnabled, startLoading, user]);

  const refillFromCard = useCallback(
    async (amountRub: number, redirectUrl: string) => {
      if (!user || !isBillingEnabled || userId === undefined) return undefined;
      startLoading();
      try {
        const { data } = await refillApi.refillFromCardDynamic(user.accountId, userId, {
          accountId: user.accountId,
          amountRub,
          redirectUrl,
        });

        return data;
      } catch (error) {
        throw error;
      } finally {
        endLoading();
      }
    },
    [endLoading, isBillingEnabled, refillApi, startLoading, user, userId]
  );
  const refillFromIncomeBalance = useCallback(
    async (amountRub: number) => {
      if (!user || !isBillingEnabled || userId === undefined) return;
      startLoading();
      try {
        await refillApi.refillFromIncomeBalance(user.accountId, userId, {
          accountId: user.accountId,
          amountRub,
        });
      } catch (error) {
        throw error;
      } finally {
        endLoading();
      }
    },
    [endLoading, isBillingEnabled, refillApi, startLoading, user, userId]
  );

  return {
    refillFromCard,
    refillFromIncomeBalance,
    getBalance,
    checkIfUserIsBlocked,
    state: {
      balanceInfo,
      isUserBlocked,
      isBillingEnabled,
    },
    isLoading,
  };
};
