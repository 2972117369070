import { createContext, FunctionComponent, useCallback, useContext, useEffect, useMemo } from 'react';

import useQueryState from 'hooks/useQueryState';
import { useCatalogCategories, useCatalogState } from '../../hooks';

import { CatalogCategoryType } from '../../types';
import { ModelInfoData } from 'api/CailagateApi/api/client';
import { ServiceTaskTypes } from 'contexts/ServicesContext/types';
import { CATALOG_FILTER_STORAGE_KEY } from '../../constants';
import { QueryStorageKeeper } from 'utils/hooks/query/QueryStorageKeeper';

export type CatalogContextType = {
  queryState: Partial<Record<STATE_PARAMS_TYPE, string>>;
  updateQueryState: (key: STATE_PARAMS_TYPE, value: string) => void;
  taskTypeCategories: CatalogCategoryType[];
  setFavorite: (serviceAccountId: number, serviceId: number, favorite: boolean) => Promise<void>;
  catalogServices?: ModelInfoData[];
  handlePageChange: ({ selected }: { selected: number }) => void;
  isLoading: boolean;
  page: number;
  pageCount?: number;
  taskTypeFilter?: ServiceTaskTypes;
  nameFilter?: string;
};

export const STATE_PARAMS = ['taskType', 'page', 'name'] as const;
export type STATE_PARAMS_TYPE = typeof STATE_PARAMS[number];

export const CatalogContext = createContext({} as CatalogContextType);

export const CatalogContextProviderComponent: FunctionComponent = ({ children }) => {
  const storageKeeper = useMemo(() => new QueryStorageKeeper<STATE_PARAMS_TYPE>(CATALOG_FILTER_STORAGE_KEY), []);

  const handleInit = useCallback(
    async (data: Partial<Record<STATE_PARAMS_TYPE, string>>) => {
      const preservedData = await storageKeeper.get();
      return { ...(preservedData ? preservedData : {}), ...data };
    },
    [storageKeeper]
  );

  const { queryState, updateQueryState, isInitialized } = useQueryState(STATE_PARAMS, handleInit);

  useEffect(() => {
    if (isInitialized) {
      storageKeeper.set(queryState);
    }
  }, [isInitialized, queryState, storageKeeper]);

  const { taskTypeCategories, loading: loadingCatalogCategories } = useCatalogCategories();

  const {
    currentItems: catalogServices,
    handlePageChange,
    isLoading: loadingCatalogState,
    page,
    pageCount,
    setFavorite,
    taskTypeFilter,
    nameFilter,
  } = useCatalogState(queryState, updateQueryState, taskTypeCategories);

  return (
    <CatalogContext.Provider
      value={{
        queryState,
        updateQueryState,
        taskTypeCategories,
        catalogServices,
        handlePageChange,
        isLoading: loadingCatalogState,
        page,
        pageCount,
        setFavorite,
        taskTypeFilter,
        nameFilter,
      }}
    >
      {children}
    </CatalogContext.Provider>
  );
};

export const useCatalogContext = () => useContext(CatalogContext);
