import React from 'react';
import InputGroup from '@just-ai/just-ui/dist/InputGroup';
import { InputText } from '@just-ai/just-ui/dist/InputText';
import { IconButtonProps } from '@just-ai/just-ui/dist/Button';
import cn from 'classnames';

export interface SearchInterface {
  value?: string;
  onChange?: (value: string, name?: string, event?: React.ChangeEvent<HTMLInputElement> | undefined) => void;
  className?: string;
  placeholder?: string;
  dataTestId?: string;
  clearable?: boolean;
  reverse?: boolean;
  dark?: boolean;
  skipSearchIcon?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  iconProps?: IconButtonProps[];
}

const baseIconProps: IconButtonProps[] = [{ name: 'farSearch', color: 'secondary' }];

const Search = function ({
  className,
  placeholder,
  value,
  onChange,
  dataTestId,
  clearable,
  onBlur,
  onFocus,
  iconProps = baseIconProps,
  reverse = false,
  dark = false,
  skipSearchIcon = false,
}: SearchInterface) {
  const [PrependInner, AppendInner] = !reverse
    ? [skipSearchIcon ? undefined : iconProps, undefined]
    : [undefined, skipSearchIcon ? undefined : iconProps];
  return (
    <div className={cn(className)}>
      <InputGroup PrependInner={PrependInner} AppendInner={AppendInner}>
        <InputText
          dark={dark}
          clearable={clearable}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          data-test-id={dataTestId}
        />
      </InputGroup>
    </div>
  );
};

export default React.memo(Search);
