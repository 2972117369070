import { createContext, FunctionComponent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Constructable, Container } from 'typedi';
import { Settings } from 'luxon';
import { register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import i18n from 'localization';

import Spinner from 'components/Spinner';
import MessageContainer, { notifyActionMessage } from 'components/MessageContainer';

import { FrontendSettings, LanguageEnum } from 'api/CailagateApi/api/client';
import { SystemConfigApiService } from 'services/ApiServices/SystemConfigApiService';

import { parseError, getUserLanguage, isProd, stringToBoolean } from 'utils';
import { StorageKeeper } from 'services/StorageKeeper';

export type AppContextType = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  diContainer: typeof Container;
  handleError: (error: any, useThrottle?: boolean) => void;
  changeLanguage: (language?: LanguageEnum) => Promise<void>;
  language: LanguageEnum;
  isBillingEnabled: boolean;
  isExtendedLanding: boolean;
  isSystemAccount: boolean;
  isArchiveEnabled: boolean;
  isImmers: boolean;
  canonicalHref?: string;
};

const DEFAULT_LANGUAGE = LanguageEnum.RU;

const IMMERS_STORAGE_KEY = 'isImmers';
const immersStorageKeeper = new StorageKeeper(IMMERS_STORAGE_KEY);
const isImmersHostname = window.location.hostname.toLowerCase()?.includes('immers');

export const AppContext = createContext({} as AppContextType);

export const AppContextProviderComponent: FunctionComponent = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [language, setLanguage] = useState<LanguageEnum>(DEFAULT_LANGUAGE);
  const errorSourcesRef = useRef<Set<string>>(new Set<string>());
  const systemConfigApi = Container.get(SystemConfigApiService);

  const [isImmersStorageFlag, setIsImmersStorageFlag] = useState<boolean>(false);

  const [frontendSettings, setFrontendSettings] = useState<FrontendSettings | undefined>(undefined);

  const getFrontendSettings = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await systemConfigApi.getFrontendSettings();
      setFrontendSettings(data);
    } catch (error) {
      setFrontendSettings({
        isBillingEnabled: false,
        isExtendedLanding: false,
        isSystemAccount: false,
        isArchiveEnabled: false,
      });
    }
    setLoading(false);
  }, [systemConfigApi]);

  const handleError = useCallback((error: any, useThrottle = false) => {
    const errorMessage = parseError(error);
    if (!useThrottle) {
      notifyActionMessage(errorMessage, { type: 'error' });
    } else if (!errorSourcesRef.current.has(errorMessage)) {
      errorSourcesRef.current.add(errorMessage);
      setTimeout(() => errorSourcesRef.current.delete(errorMessage), 30000);
      notifyActionMessage(errorMessage, { type: 'error' });
    }
  }, []);

  const changeLanguage = useCallback(async (language?: LanguageEnum) => {
    const userLanguage = getUserLanguage(language);
    Settings.defaultLocale = userLanguage;
    await i18n.changeLanguage(userLanguage.toLowerCase());
    setLanguage(userLanguage);
    //TODO: axios headers language update
  }, []);

  const [isAppLaunching, setIsAppLaunching] = useState(true);

  async function init() {
    register(await connect());
  }

  useEffect(() => {
    Promise.allSettled([changeLanguage(DEFAULT_LANGUAGE), init(), getFrontendSettings()])
      .then(() => {
        immersStorageKeeper.get().then(storageValue => {
          if (storageValue.success) {
            setIsImmersStorageFlag(stringToBoolean(storageValue?.payload?.toString() || ''));
          }
        });
      })
      .finally(() => setIsAppLaunching(false));
  }, [changeLanguage, getFrontendSettings]);

  const canonicalHref = isProd() ? 'https://caila.io' : undefined;

  if (isAppLaunching || !frontendSettings) return null;

  return (
    <AppContext.Provider
      value={{
        diContainer: Container,
        loading,
        language,
        canonicalHref,
        setLoading,
        handleError,
        changeLanguage,
        isBillingEnabled: frontendSettings.isBillingEnabled,
        isExtendedLanding: frontendSettings.isExtendedLanding,
        isSystemAccount: frontendSettings.isSystemAccount,
        isArchiveEnabled: frontendSettings.isArchiveEnabled,
        isImmers: isImmersStorageFlag || isImmersHostname,
      }}
    >
      <Helmet>
        <meta
          name='description'
          content='Платформа для хостинга ML-сервисов. API для работы с ML-моделями'
          data-react-helmet='true'
        />
        <title>Caila | MLOps платформа. API-сервер для ML-моделей</title>
      </Helmet>
      {children}
      <Spinner zIndex={1000} show={loading} />
      <MessageContainer />
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
export const useDI = <CONTAINER_TYPE extends unknown>(type: Constructable<CONTAINER_TYPE>) =>
  useAppContext().diContainer.get(type);
