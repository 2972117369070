import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { DateTime } from 'luxon';

import { isDevEnvironment, isImmersEnv } from 'utils';
import styles from './styles.module.scss';

type BuildInfoType = { frontend: string; backend: string };
type BuildInfoResponseType = { buildBranch: string; buildDate: string };

const parseDate = (data: BuildInfoResponseType | undefined) => {
  if (!data) return '';
  const buildDate = data?.buildDate || '';
  const buildBranch = data?.buildBranch || '';
  const result = DateTime.fromISO(buildDate).toFormat('dd.MM.yyyy HH:mm');
  return `${result.includes('Invalid DateTime') ? buildDate : result}, ${buildBranch}`;
};

const BuildInfo = () => {
  const [buildInfo, setBuildInfo] = useState<BuildInfoType | undefined>();

  const getBuildInfo = useCallback(async () => {
    let frontendData, backendData;
    try {
      frontendData = (await axios.get<BuildInfoResponseType>('/version')).data;
    } catch (e) {
      console.error(e);
    }
    try {
      backendData = (await axios.get<BuildInfoResponseType>('/api/mlpgate/version')).data;
    } catch (e) {
      console.error(e);
    }
    const frontend = parseDate(frontendData);
    const backend = parseDate(backendData);
    setBuildInfo({ frontend, backend });
  }, []);

  useEffect(() => {
    getBuildInfo();
  }, [getBuildInfo]);

  return isDevEnvironment() && !isImmersEnv() && buildInfo ? (
    <div className={styles.buildInfo}>
      {buildInfo?.frontend && (
        <span>
          <b>front: </b>
          {buildInfo.frontend}
        </span>
      )}
      {buildInfo?.backend && (
        <span>
          <b>back: </b>
          {buildInfo?.backend}
        </span>
      )}
    </div>
  ) : null;
};

export default React.memo(BuildInfo);
