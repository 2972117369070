import React from 'react';

import Chip, { ChipProps } from 'components/Chip';
import cn from 'classnames';

import styles from './styles.module.scss';

export enum TagsChipType {
  TextTask,
  Language,
  AvailableInJaicp,
  App,
}

const typeMap: Record<TagsChipType, { class: string }> = {
  [TagsChipType.TextTask]: {
    class: styles.textTask,
  },
  [TagsChipType.Language]: {
    class: styles.language,
  },
  [TagsChipType.AvailableInJaicp]: {
    class: styles.availableInJaicp,
  },
  [TagsChipType.App]: {
    class: styles.app,
  },
};

export interface TagsChipProps extends Omit<ChipProps, 'className'> {
  type: TagsChipType;
  className?: string;
}

export interface TagsChipElement extends TagsChipProps {
  key: string;
}

const TagsChip = ({ type, className, ...props }: TagsChipProps) => {
  const typeValue = typeMap[type];
  return <Chip {...props} className={cn(typeValue.class, className)} />;
};

export default React.memo(TagsChip);
