import React from 'react';
import { history } from 'App';

import Button from 'components/Button';
import { ModelInfoData } from 'api/CailagateApi/api/client';
import { t } from 'localization';
import { useCloneModal } from 'modules/Account/components/CloneModal';
import { useDI } from 'contexts/AppContext';
import { ModelApiService } from 'services/ApiServices/ModelApiService';
import { WORKSPACE_BASE_PATH } from 'routes';

interface PrototypeServiceTestWidgetInterface {
  serviceData: ModelInfoData;
}

const PrototypeServiceTestWidget = ({ serviceData }: PrototypeServiceTestWidgetInterface) => {
  const modelApi = useDI(ModelApiService);
  const handleClone = async ({ name }: { name: string }) => {
    const {
      id: { modelId, accountId },
    } = serviceData;
    try {
      const {
        data: { id },
      } = await modelApi.createClonedModel(accountId.toString(), modelId.toString(), name);
      history.push(`/${WORKSPACE_BASE_PATH}/model/${id.accountId}/${id.modelId}`);
    } catch (error) {
      throw error;
    }
  };
  const [CloneModalNode, openCloneModal] = useCloneModal(handleClone);
  return (
    <>
      <Button
        type='button'
        color='primary'
        onClick={openCloneModal}
        style={{ marginTop: '25px', width: 'max-content', maxWidth: '295px', alignSelf: 'center' }}
        data-test-id='Button:TestPrototypeServiceTestWidget'
      >
        {t('PrototypeServiceTestWidget:CloneAndLaunch')}
      </Button>
      {CloneModalNode}
    </>
  );
};

export default React.memo(PrototypeServiceTestWidget);
